"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.forgetAccount = forgetAccount;
exports.forgetCheckUpdate = forgetCheckUpdate;
exports.forgetEmail = forgetEmail;
exports.forgetMobile = forgetMobile;
exports.submitForgetEmail = submitForgetEmail;
exports.submitForgetGoogle = submitForgetGoogle;
exports.submitForgetMobile = submitForgetMobile;
exports.submitForgetUniversal = submitForgetUniversal;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER } = process.env

// // 忘记密码四合一提交验证码
// export function submitForgetUniversal(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/Account/submitForgetUniversal`,
//     data,
//   });
// }
// 忘记密码四合一提交验证码
function submitForgetUniversal(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/Forget/verifyUniversal`,
    data
  });
}

// 检测手机号
function forgetMobile(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/forgetMobile`,
    data
  });
}

// 检测邮箱
function forgetEmail(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/forgetEmail`,
    data
  });
}

// 检测账号
function forgetAccount(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/forgetAccount`,
    data
  });
}

// 通过手机号进行信息验证
function submitForgetMobile(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/submitForgetMobile`,
    data
  });
}

// 通过邮箱进行信息验证

function submitForgetEmail(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/submitForgetEmail`,
    data
  });
}

//通过谷歌进行信息验证
function submitForgetGoogle(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/submitForgetGoogle`,
    data
  });
}

// // 密码重置
// export function forgetCheckUpdate(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/Account/forgetCheckUpdate`,
//     data,
//   });
// }
// 密码重置
function forgetCheckUpdate(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/Forget/resetPwd`,
    data
  });
}