"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _stationSetting = require("@/assets/js/stationSetting");
var _vuex = require("vuex");
var _account = require("@/model/user/account");
var _message = require("@/components/dialog/message");
var _CheckAccount = _interopRequireDefault(require("./components/CheckAccount"));
var _ConfirmPwd = _interopRequireDefault(require("./components/ConfirmPwd"));
var _i18n = require("@/assets/js/i18n");
var _vuePuzzleVcode = _interopRequireDefault(require("vue-puzzle-vcode"));
var _ = _interopRequireDefault(require("../../assets/img/rePhoto/1.png"));
var _2 = _interopRequireDefault(require("../../assets/img/rePhoto/2.png"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _Geetest = _interopRequireDefault(require("@/components/Geetest.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//页面内引用插件
var _default = {
  data() {
    return {
      img1: _.default,
      img2: _2.default,
      imgs: [],
      isShow: false,
      forgetData: {},
      forgetPwdLoading: false,
      only_name: "",
      isInCheck: false,
      isInChange: false,
      validInfo: {},
      formRules: {
        account: [{
          required: true,
          message: this.translateTitle(`邮箱/手机号/用户名不能为空`),
          trigger: "change"
        }]
      },
      isShowGeet: false,
      geetParam: null,
      pwdToken: ""
    };
  },
  components: {
    ConfirmPwd: _ConfirmPwd.default,
    CheckAccount: _CheckAccount.default,
    Vcode: _vuePuzzleVcode.default,
    Geetest: _Geetest.default
    // InputNumber
  },

  created() {
    if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w") {
      this.imgs.push(_.default);
    } else {
      this.imgs.push(_2.default);
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    onFilterAccount(val) {
      this.$nextTick(() => {
        this.$refs.checkForm.validateField("account");
      });
    },
    onSecret(val) {
      this.pwdToken = val;
      // this.only_name = val;
      this.isInChange = true;
      this.isInCheck = false;
    },
    success(geetParam) {
      this.geetParam = geetParam;
      this.onSubmit();
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },

    onCloseVcode() {
      this.isShow = false;
    },
    onCancel() {
      this.isInCheck = false;
    },
    onShowVcode() {
      this.$refs.checkForm.validate(valid => {
        if (valid) {
          // this.isShow = true;
          this.isShowGeet = !this.isShowGeet;
        } else {
          this.isShow = false;
        }
      });
    },
    onSubmit() {
      this.$refs.checkForm.validate(valid => {
        if (valid) {
          const data = {
            login_name: this.forgetData.account,
            ...this.geetParam
          };
          this.forgetPwdLoading = true;
          (0, _account.forgotPwdCheckAccountGeet)(data).then(rs => {
            this.forgetPwdLoading = false;
            if (rs.status == 200) {
              const {
                validate_email,
                validate_google,
                validate_mobile
              } = rs.data;
              if (validate_email == 0 && validate_google == 0 && validate_mobile == 0) {
                (0, _message.showMessage)({
                  message: "账号未绑定谷歌或邮箱，无法重置密码"
                });
                return;
              } else {
                this.isInCheck = true;
                this.validInfo = rs.data;
                sessionStorage.setItem("user_info", JSON.stringify(this.validInfo));
              }
            }
          });
        }
      });
    }
  }
};
exports.default = _default;