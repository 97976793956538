"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _jsMd = _interopRequireDefault(require("js-md5"));
var _message = require("@/components/dialog/message");
var _forgetPwd = require("@/model/user/forgetPwd");
var _i18n = require("@/assets/js/i18n");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  directives: {
    focus: {
      update: (el, {
        value,
        oldValue
      }) => {
        if (value != oldValue) {
          el.querySelector("input").focus();
        }
      }
    }
  },
  props: ["onlyName", "token"],
  data() {
    return {
      pwdData: {},
      pwdLevel: 0,
      showLevel: false,
      pwdFocus: false,
      confirmFocus: false,
      spotLevel: {},
      formRules: {
        confirmPwd: [{
          validator: this.confirmBlur,
          trigger: "change"
        }]
      },
      confirmOutline: false,
      resetLoading: false
    };
  },
  created() {
    this.initialData();
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    // 密码框失焦
    pwdBlur() {
      this.showLevel = false;
    },
    // 确认密码
    confirmBlur(rule, value, callback) {
      let {
        pwd
      } = this.pwdData;
      if (value === "" | !value) {
        callback(new Error(this.translateTitle("请输入确认密码")));
      } else if (value !== pwd) {
        this.confirmOutline = true;
        callback(new Error(this.translateTitle("两次输入密码不一致!")));
      } else {
        callback();
      }
    },
    // 重置密码点击
    resetPwd() {
      let {
        pwdData: {
          pwd,
          confirmPwd
        },
        spotLevel: {
          digit,
          enNum
        }
      } = this;
      if (digit && enNum) {
        if (pwd !== confirmPwd) {
          this.confirmFocus = !this.confirmFocus;
        } else {
          this.confirmOutline = false;
          this.submitNewPwd();
        }
      } else {
        this.pwdFocus = !this.pwdFocus;
      }
    },
    // 密码提交
    async submitNewPwd() {
      let {
        pwdData: {
          pwd
        }
      } = this;
      // let only_name = this.onlyName;
      pwd = (0, _jsMd.default)(pwd);
      this.resetLoading = true;
      // let { msg, status } = await forgetCheckUpdate({ pwd, only_name });
      let {
        msg,
        status
      } = await (0, _forgetPwd.forgetCheckUpdate)({
        pwd,
        token: this.token
      });
      if (status == 200) {
        // 去登录页
        this.resetLoading = false;
        (0, _message.showMessage)({
          message: this.translateTitle(`设置新密码成功`),
          type: "success"
        });
        this.$router.push({
          name: "Login"
        });
      } else {
        this.resetLoading = false;
        (0, _message.showMessage)({
          message: msg ? msg : status
        });
      }
    },
    // 检测密码是否合规
    checkChange() {
      let {
        pwd
      } = this.pwdData;
      let length = pwd.length;
      if (!pwd || /^\s$/.test(pwd)) {
        this.initialData();
      } else if (length < 6) {
        // 一级灰点
        this.spotLevel.digit = false;
        this.check_level(pwd);
        this.pwdLevel = 0;
      } else if (6 <= length && length < 10) {
        // 一级绿点
        this.spotLevel.digit = true;
        this.check_level(pwd);
      } else {
        this.spotLevel.digit = true;
        this.check_level(pwd);
      }
    },
    // 检测英文数字：必须包含英文和数字，允许包含符号（不允许包含空格）
    check_En_Num(val) {
      if (/^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    // 检测符号：必须包含符号，允许包含 英文，数字，空格
    check_En_Num_Symbol(val) {
      if (/^(?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+)[\sa-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    check_level(pwd) {
      let length = pwd.length;
      if (this.check_En_Num(pwd)) {
        // 二级绿点
        this.spotLevel.enNum = true;
        // 密码等级
        if (6 <= length && length < 8) {
          this.pwdLevel = 1;
        } else if (length >= 8) {
          this.pwdLevel = 2;
        }
      } else {
        // 二级灰点
        this.spotLevel.enNum = false;
      }
      if (this.check_En_Num_Symbol(pwd)) {
        // 三级绿点
        this.spotLevel.symbol = true;
        // 密码等级
        if (6 <= length && length < 8) {
          this.pwdLevel = 1;
        } else if (length >= 8) {
          if (this.check_En_Num(pwd)) {
            this.pwdLevel = 3;
          }
        }
      } else {
        // 三级灰点
        this.spotLevel.symbol = false;
      }
      if (/^[a-zA-Z]*$/.test(pwd)) this.pwdLevel = 0;
      if (/^[0-9]*$/.test(pwd)) this.pwdLevel = 0;
      if (/^[?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]*$/.test(pwd)) this.pwdLevel = 0;
      if (/^[0-9?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]*$/.test(pwd)) this.pwdLevel = 0;
      if (/^[a-zA-Z?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]*$/.test(pwd)) this.pwdLevel = 0;
      if (!this.check_En_Num(pwd)) this.pwdLevel = 0;
    },
    initialData() {
      this.pwdLevel = 0;
      this.spotLevel = {
        digit: false,
        enNum: false,
        symbol: false
      };
    }
  },
  computed: {
    levelText() {
      let tempLevel = "";
      switch (this.pwdLevel) {
        case 0:
          tempLevel = "";
          break;
        case 1:
          tempLevel = "弱";
          break;
        case 2:
          tempLevel = "中";
          break;
        case 3:
          tempLevel = "强";
      }
      return tempLevel;
    }
  }
};
exports.default = _default;