"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var Settings = _interopRequireWildcard(require("@/model/settings"));
var _i18n = require("@/assets/js/i18n");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _security = require("@/model/user/security");
var _forgetPwd = require("@/model/user/forgetPwd");
var _message = require("@/components/dialog/message");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _vuex = require("vuex");
var _globalFun = _interopRequireDefault(require("@/assets/js/globalFun.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Code from "./Code";
var _default = {
  props: {
    isInCheck: {
      type: Boolean,
      default: false
    },
    validInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      emailCountDown: {
        countDown: -1,
        // 倒计时计数
        countDownTimer: null,
        // 倒计时容器
        countDownStatus: false // 倒计时点击状态
      },

      mobileCountDown: {
        countDown: -1,
        // 倒计时计数
        countDownTimer: null,
        // 倒计时容器
        countDownStatus: false // 倒计时点击状态
      },

      user_id: "",
      all_areacode: [],
      // 所有区号
      forgetData: {
        area: 86
      },
      formRules: {
        mobile_code: [{
          required: true,
          message: this.translateTitle(`请输入正确的6位手机验证码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位手机验证码`),
          trigger: "blur"
        }],
        email_code: [{
          required: true,
          message: this.translateTitle(`请输入正确的6位邮箱验证码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位邮箱验证码`),
          trigger: "blur"
        }],
        google_code: [{
          required: true,
          message: this.translateTitle(`请输入正确的6位谷歌验证码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位谷歌验证码`),
          trigger: "blur"
        }]
      },
      valid: {
        email: true,
        mobile: true,
        google: false,
        username: true
      },
      forgetLoading: false,
      mobileCounting: false,
      emailCounting: false,
      globalFun: _globalFun.default
    };
  },
  created() {
    this.getAreaCode();
    document.onkeyup = e => {
      let keycode = document.all ? event.keyCode : e.which;
      // 在登录界面 且 按得键是回车键enter
      if (this.$route.path == "/forgetPwd" && keycode === 13) {
        this.handSubmit();
      }
    };
  },
  watch: {
    validInfo: {
      handler(val) {
        this.forgetData.email = val.email;
        this.forgetData.mobile = val.mobile;
      },
      immediate: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    onToForget() {
      this.$emit("onCancel");
    },
    onSercuity() {
      // this.$router.push(this.routeSplicing(this.language, "loginSecurity"));
      this.$router.push({
        path: this.routeSplicing(this.language, "loginSecurity"),
        query: {
          type: "formForgotPassword"
        }
      });
    },
    // 发送手机验证码
    onMobileSendCode() {
      const data = {
        // type: 23,
        // mobile: this.validInfo.mobile,
        // area: this.validInfo.area,
        // user_id: this.validInfo.user_id,
        token: this.validInfo.token
      };
      (0, _security.sendForgetSmsCode)(data).then(rs => {
        if (rs.status == 200) {
          this.mobileCounting = true;
          (0, _message.showMessage)({
            message: this.translateTitle(`发送成功`),
            type: "success"
          });
          this.mobileCountDown.countDown = 60;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
      });
    },
    // 发送邮箱验证码
    onEmailSendCode() {
      const data = {
        // type: 24,
        // email: this.validInfo.email,
        // user_id: this.validInfo.user_id,
        token: this.validInfo.token
      };
      (0, _security.sendForgetEmailCode)(data).then(rs => {
        if (rs.status == 200) {
          this.emailCounting = true;
          (0, _message.showMessage)({
            message: this.translateTitle(`发送成功`),
            type: "success"
          });
          this.emailCountDown.countDown = 60;
          this.emailCountDown.countDownTimer = setInterval(() => {
            if (this.emailCountDown.countDown === 0) {
              clearInterval(this.emailCountDown.countDownTimer);
              this.emailCountDown.countDownStatus = false;
            }
            this.emailCountDown.countDown--;
          }, 1000);
        } else {
          this.emailCountDown.countDownStatus = false;
        }
      });
    },
    handSubmit() {
      this.$refs.validatorForm.validate(valid => {
        if (valid) {
          this.forgetLoading = true;
          // 检测是否存在该账号
          const data = {};
          const {
            token,
            validate_email,
            validate_google,
            validate_mobile,
            email,
            mobile,
            user_id,
            username
          } = this.validInfo;
          data.token = token;
          // data.user_id = user_id;
          if (validate_email == 1) {
            // (data.email = email),
            data.email_code = this.forgetData.email_code;
          }
          if (validate_mobile == 1) {
            // (data.mobile = mobile),
            data.mobile_code = this.forgetData.mobile_code;
          }
          if (validate_google == 1) {
            // (data.username = username),
            data.google_code = this.forgetData.google_code;
          }
          (0, _forgetPwd.submitForgetUniversal)(data).then(rs => {
            this.forgetLoading = false;
            if (rs.status == 200) {
              this.$emit("secret", rs.data.token);
            }
          });
        }
        return;
      });
    },
    /* 获取手机区号 */
    async getAreaCode() {
      const {
        data
      } = await Settings.apiGetStaticTableInfo(Settings.SETTING_SV_ID.static_sms_country);
      if (!data) {
        return false;
      }
      this.all_areacode = data;
    }
  },
  components: {
    InputNumber: _InputNumber.default
  }
};
exports.default = _default;